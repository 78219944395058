<template>
  <div>
    <div :class="$style.block">
      <div class="row">
        <div class="col-xl-12">
          <div :class="$style.inner">
            <div :class="$style.form">
              <div :class="$style.logoContainer">
                <img v-lazy="$companyLogoPath" alt="">
              </div>
              <a-form class="login-form" :form="form" @submit="handleSubmit">
                <a-form-item
                  label="E-mail"
                  :validate-status="error('email') ? 'error' : ''"
                  :help="error('email') || ''"
                >
                  <a-input
                    placeholder="E-mail"
                    v-decorator="['email', { rules: [{ required: true, message: 'E-mail musí být vyplněn!' }]}]"
                  >
                    <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25);"/>
                  </a-input>
                </a-form-item>
                <a-form-item class="mt-4">
                  <a-alert
                    message="Pokyny pro obnovení hesla"
                    description="Po odeslání formuláře obdržíte e-mail s odkazem na vytvoření nového hesla."
                    type="info"
                    showIcon
                  />
                </a-form-item>
                <div class="form-actions">
                  <a-button
                    type="primary"
                    html-type="submit"
                    class="login-form-button"
                    :block="true"
                    size="large"
                    :loading="loading"
                    :disabled="loading || hasErrors(form.getFieldsError())"
                  >Potvrdit</a-button>
                </div>
                <div class="text-center">
                  <router-link
                    class="text-primary utils__link--blue utils__link--underlined"
                    to="/auth/login"
                  >Už jsem si vzpomněl</router-link>
                </div>
              </a-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { hasErrors } from '../../../services/forms'

export default {
  data: function () {
    return {
      loading: false,
      form: this.$form.createForm(this),
      hasErrors,
    }
  },
  methods: {
    error(fieldName) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(fieldName) && getFieldError(fieldName)
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('auth/forgotPass', { email: this.form.getFieldValue('email') })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
